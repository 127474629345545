.Configurator {
    &Body {
        @apply lg:tw-overflow-y-hidden;

        .NavigationBurger {
            @apply lg:tw-translate-x-3;
        }

        @media (min-width: 1024px) {
            .NavigationBurger {
                .navigation-toggle {
                    > span {
                        @apply before:tw-bg-gray-700;
                    }
                }
            }
        }
    }

    &Steps {
        @apply tw-flex tw-justify-between tw-items-center;
        counter-reset: step-counter;

        .step {
            @apply tw-outline tw-outline-4 tw-outline-white tw-bg-white tw-relative tw-flex tw-text-center tw-rounded-full tw-border tw-border-gray-100 tw-justify-center tw-items-center tw-h-8 tw-w-8 tw-font-medium tw-z-10;

            &.done {
                @apply tw-text-brand-500 tw-border-brand-500 tw-cursor-pointer tw-transition-colors tw-duration-300 hover:tw-bg-brand-500 hover:tw-text-white;
            }

            &.active {
                @apply tw-visible tw-relative tw-pointer-events-auto tw-flex tw-text-white tw-bg-brand-500 tw-border-brand-500;
            }

            &::before {
                counter-increment: step-counter;
                content: counter(step-counter);
            }
        }
    }

    label {
        &::after {
            content: none !important;
        }
    }

    &Select {
        input {
            @apply tw-hidden;
        }

        label {
            @apply tw-rounded-md tw-flex tw-transition-all tw-duration-300 tw-ease-in-out;
        }

        //input:checked~label,
        label:hover {
            @apply tw-text-brand-500 tw-px-4 tw-shadow-configurator tw-border-white;
        }

        label.ConfiguratorSelectLabelType:hover {
            @apply tw-text-brand-500 tw-px-2.5 tw-shadow-none;
        }
    }

    &ScrollArea {
        /* width */
        &::-webkit-scrollbar {
            @apply  tw-w-[3px] sm:tw-w-1 md:tw-w-[5px];
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            @apply tw-bg-gray-100/70;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            @apply tw-bg-brand-500 tw-rounded-full;

            &:hover {
                @apply tw-bg-brand-500/80;
            }
        }
    }

    &Grid {
        input {
            @apply tw-hidden;
        }

        label {
            @apply tw-relative tw-rounded-md tw-flex tw-transition-all tw-duration-300 tw-ease-in-out;

            &::before {
                content: '';
                background: url(../assets/configurator-checkmark.svg) center no-repeat;
                background-size: 56%;
                @apply tw-transition-opacity tw-rounded-br-sm tw-opacity-0 tw-block tw-absolute tw-top-2 tw-left-2 md:tw-top-3 md:tw-left-3 lg:tw-left-2 lg:tw-top-2 xl:tw-top-3 xl:tw-left-3 tw-w-7 tw-h-7 tw-bg-white tw-z-20 tw-pointer-events-none;
            }
        }

        &Item {
            @apply tw-relative;
        }

        input:checked~label,
        label:hover {
            @apply tw-shadow-configurator;
        }

        input:checked~label {
            &::before {
                content: '';
                @apply tw-opacity-100;
            }
            
            .Divider {
                @apply tw-bg-transparent;
            }
        }
    }

    &List {
        input {
            @apply tw-hidden;
        }

        label {
            @apply tw-relative tw-rounded-md tw-flex tw-transition-all tw-duration-300 tw-ease-in-out;

            &::before {
                content: '';
                background: url(../assets/configurator-checkmark.svg) center no-repeat;
                background-size: 56%;
                @apply tw-transition-opacity tw-rounded-br-sm tw-opacity-0 tw-block tw-absolute tw-w-7 tw-h-7 tw-bg-white tw-z-20 tw-pointer-events-none;
            }
        }

        input:checked~label {
            @apply tw-shadow-configurator;
        }

        input:checked~label {
            &::before {
                content: '';
                @apply tw-opacity-100;
            }
        }

        input:checked~label .TextContent {
            &::after {
                @apply tw-bg-transparent;
            }
        }
    }

    &Item {
        @apply tw-relative;

        &Partner {
            @apply tw-transition-all tw-duration-300 tw-ease-in-out;

            &:hover,
            &.active {
                .icon {
                    @apply tw-text-brand-500;
                }
            }

            &.active {
                @apply tw-py-5;
            }
        }

        &Model {
            @apply tw-absolute tw-top-0 tw-right-0 tw-h-full;
            width: 30%;
            background-color: rgba(255, 255, 255, 0.8);

            &::before {
                @apply tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
                width: 75%;
                display: block;
                content: '';
                aspect-ratio: 2 / 4;
                background: center no-repeat;
                background-size: contain;
                background-color: white;
            }

            &.Alba::before {
                background-image: url(../assets/configurator/icons/Alba.svg);
            }

            &.Ferus::before {
                background-image: url(../assets/configurator/icons/Ferus.svg);
            }

            &.FerS::before {
                background-image: url(../assets/configurator/icons/Ferus_slim.svg);
            }

            &.Flamm::before {
                background-image: url(../assets/configurator/icons/Flamm.svg);
            }

            &.Ignis::before {
                background-image: url(../assets/configurator/icons/Ignis.svg);
            }

            &.Lacus::before {
                background-image: url(../assets/configurator/icons/Lacus.svg);
            }

            &.Level::before {
                background-image: url(../assets/configurator/icons/Level.svg);
            }

            &.Life::before {
                background-image: url(../assets/configurator/icons/Life.svg);
            }

            &.Saeva::before {
                background-image: url(../assets/configurator/icons/Saeva.svg);
            }

            &.SaeS::before {
                background-image: url(../assets/configurator/icons/Saeva_slim.svg);
            }

            &.Taurus::before {
                background-image: url(../assets/configurator/icons/Taurus.svg);
            }

            &.Verti::before {
                background-image: url(../assets/configurator/icons/Vertikal.svg);
            }

            &.Viva::before {
                background-image: url(../assets/configurator/icons/Viva.svg);
            }
        }
    }

    &Filters {
        .option-Alba,
        .option-FerS,
        .option-Ferus,
        .option-Flamm,
        .option-Ignis,
        .option-LacLS,
        .option-Level,
        .option-Life,
        .option-SaeS,
        .option-Saeva,
        .option-TauLS,
        .option-Verti,
        .option-Viva
        {
            display: flex;
            justify-content: flex-start;
             align-items: center;

            &::before {
                @apply tw-border tw-border-gray-100;
                content: "";
                width: 1.8rem;
                aspect-ratio: 2 / 4;
                margin-right: 10px;
                background-color: #fff;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .option-Alba::before {
            background-image: url(../assets/configurator/icons/Alba.svg);
        }

        .option-Ferus::before {
            background-image: url(../assets/configurator/icons/Ferus.svg);
        }

        .option-FerS::before {
            background-image: url(../assets/configurator/icons/Ferus_slim.svg);
        }

        .option-Flamm::before {
            background-image: url(../assets/configurator/icons/Flamm.svg);
        }

        .option-Ignis::before {
            background-image: url(../assets/configurator/icons/Ignis.svg);
        }

        .option-Lacus::before {
            background-image: url(../assets/configurator/icons/Lacus.svg);
        }

        .option-Level::before {
            background-image: url(../assets/configurator/icons/Level.svg);
        }

        .option-Life::before {
            background-image: url(../assets/configurator/icons/Life.svg);
        }

        .option-Saeva::before {
            background-image: url(../assets/configurator/icons/Saeva.svg);
        }

        .option-SaeS::before {
            background-image: url(../assets/configurator/icons/Saeva_slim.svg);
        }

        .option-Taurus::before {
            background-image: url(../assets/configurator/icons/Taurus.svg);
        }

        .option-Verti::before {
            background-image: url(../assets/configurator/icons/Vertikal.svg);
        }

        .option-Viva::before {
            background-image: url(../assets/configurator/icons/Viva.svg);
        }
    }

    .theme-label {
        @apply tw-text-14 tw-text-gray-700 tw-mb-2;
    }

    .theme-border {
        @apply tw-border tw-border-solid tw-rounded-sm tw-border-gray-100;
    }

    .disabled {
        @apply tw-cursor-not-allowed tw-pointer-events-none tw-opacity-50;
    }
}
