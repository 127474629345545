.TestimonialSlider {
    .arrow {
        @apply tw-transition-transform;

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        &:hover {
            @apply tw-opacity-50 tw-transition-opacity;
        }
    }

    .bullet {
        .bullet-dot {
            max-width: 10px;
            max-height: 10px;
        }

        &:hover .bullet-dot {
            @apply tw-bg-brand-300;
        }
        &.active .bullet-dot {
            @apply tw-bg-brand-500;
        }
    }
}
