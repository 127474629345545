.ShopHero {
    .pimcore_editable_image {
        @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full tw-object-cover tw-object-center tw-overflow-hidden;
        width: 100% !important;
        height: 100% !important;

        img {
            @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-w-full tw-h-full tw-object-cover tw-object-center tw-overflow-hidden;
            width: 100% !important;
            height: 100% !important;
        }
    }
}