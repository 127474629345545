.ConfiguratorTeaser {
    @media screen and (min-width: theme('screens.md')) {
        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 50%;
            z-index: -50;
        }

        &::before {
            left: 0;
            background: #D6D6DA;
        }

        &::after {
            right: 0;
            background: #F9F9F9;
        }
    }

    &.is-editmode {
        background: transparent;

        &::after {
            background: rgb(255, 255, 255);
            background: radial-gradient(circle, rgba(255, 255, 255, 0.15) 50%, rgb(251, 251, 251) 100%);
        }
    }
}