.Navigation {
    .navigation-toggle {
        span {
            @apply tw-block tw-top-0 tw-left-0 tw-w-full tw-h-full tw-absolute tw-transform tw-transition-all;

            &::before {
                content: '';
                @apply tw-block tw-top-1/2 tw--translate-y-1/2 tw-w-full tw-absolute tw-transform tw-transition-all tw-duration-500 tw-rounded-xs;
                height: 2px;

                @media screen and (min-width: theme('screens.md')) {
                    height: 2.5px;
                }
            }

            &:first-child {
                transform: translateY(-25%);
            }

            &:last-child {
                transform: translateY(25%);
            }
        }

        &:hover {
            span {
                &:first-child {
                    transform: translateY(-20%);
                }

                &:last-child {
                    transform: translateY(20%);
                }
            }
        }

        &.opened {
            span {
                &:first-child {
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    transform: translateX(100%);
                    opacity: 0;
                }

                &:last-child {
                    transform: rotate(-45deg);
                }
            }

            &:hover {
                span {
                    &:first-child {
                        transform: rotate(35deg);
                    }

                    &:nth-child(2) {
                        transform: translateX(100%);
                        opacity: 0;
                    }

                    &:last-child {
                        transform: rotate(-35deg);
                    }
                }
            }
        }
    }

    ul.menu,
    ul.sub-menu {
        li {
            &:last-child {
                a {
                    @apply tw-border-b tw-border-gray-700;
                }
            }
        }
    }
}