@tailwind base;
@tailwind components;
@tailwind utilities;

@import "main/import-fonts";
@import "main/base";
@import "main/buttons";
@import "main/wysiwyg";
@import "main/components";
@import "main/gdpr-consent";
@import "main/form";
@import "main/swiper";
@import "main/shop";
@import "main/checkout";
@import "main/configurator";
@import "main/datasheet";
@import "main/loader";
@import "main/effects";
@import "main/navigation";
@import "main/plyr";
@import "main/screen-height";
