.hover-arrow {
    svg {
        @apply tw-ml-2 tw-transition-all tw-duration-300 tw-ease-in-out tw-transform tw-text-current;
    }

    &:hover {
        svg {
            @apply tw-translate-x-3;
        }
    }
}

.hover-underline-animation {
    @apply tw-inline-flex tw-relative;
}

.hover-underline-animation::after {
    @apply tw-absolute tw-block tw-w-full tw-bg-current tw-left-0;
    content: '';
    height: 1px;
    bottom: 1px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}