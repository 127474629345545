.Wysiwyg {
    ul {
        li {
            @apply tw-relative tw-pl-6 tw-mb-1 sm:tw-mb-3;
            list-style-type: none;

            &:before {
                content: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.8499 2.24999L6.24985 12.85C6.15597 12.9447 6.02817 12.9979 5.89485 12.9979C5.76153 12.9979 5.63374 12.9447 5.53985 12.85L0.149852 7.45999C0.0551959 7.36611 0.00195312 7.23831 0.00195312 7.10499C0.00195312 6.97168 0.0551959 6.84388 0.149852 6.74999L0.849852 6.04999C0.943735 5.95534 1.07153 5.9021 1.20485 5.9021C1.33817 5.9021 1.46597 5.95534 1.55985 6.04999L5.88985 10.38L15.4399 0.829995C15.6377 0.638211 15.952 0.638211 16.1499 0.829995L16.8499 1.53999C16.9445 1.63388 16.9978 1.76168 16.9978 1.89499C16.9978 2.02831 16.9445 2.15611 16.8499 2.24999Z' fill='%23A68659'/%3E%3C/svg%3E%0A");
                @apply tw-absolute tw-left-0;
            }
        }
    }
}

.wysiwyg-link{
    a{
        @apply tw-underline tw-text-brand-500;
    }
}