.ContactForm {
    &[data-contact-type=""],
    &[data-contact-type="contact"] {
        #contact_alternateDate_row {
            @apply tw-hidden;
        }
        #contact_date_row {
            @apply tw-hidden;
        }
    }

    #contact_date_row, #contact_alternateDate_row {
        &.form-row {
           .error-icon {
               right: 2.5rem;
           }
        }
    }

    .PartnerSearchForm.with-image{
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6,
        label{
            @apply tw-text-white;
        }
    }
}
