.btn {
    &.primary {
        @apply tw-uppercase tw-relative tw-text-white tw-text-16 tw-font-bold tw-tracking-wide tw-bg-brand-500 tw-rounded-sm tw-no-underline;
        padding: 13px 24px 11px;

        &::before {
            @apply tw-absolute tw-block tw-w-full tw-h-full tw-bg-white tw-left-0 tw-top-0 tw-transform tw-transition-all tw-duration-500 tw-rounded-xs;
            opacity: 0.10;
            content: '';
            height: 100%;
            bottom: 1px;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        &:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }

    &.outline {
        @apply tw-uppercase tw-relative tw-ml-px tw-text-brand-500 tw-text-16 tw-font-bold tw-tracking-wide tw-bg-transparent tw-rounded-sm tw-outline tw-outline-1 tw-outline-brand-500 tw-no-underline;
        padding: 13px 24px 11px;

        &.white {
            @apply tw-text-white tw-outline-white tw-bg-transparent;
        }

        &.overlay {
            @apply tw-bg-white tw-z-20;
        }

        &.brand {
            @apply tw-text-brand-500 tw-outline-brand-500 tw-transition-colors tw-duration-300 tw-ease-in-out hover:tw-text-white hover:tw-outline-brand-500 hover:tw-bg-brand-500;
        }

        &.gray {
            @apply tw-text-gray-300 tw-outline-gray-300 tw-transition-colors tw-duration-300 tw-ease-in-out hover:tw-text-white hover:tw-outline-brand-500 hover:tw-bg-brand-500;
        }
    }

    &.link {
        @apply tw-uppercase tw-relative tw-text-brand-500 tw-no-underline;
    }

    &.tertiary {
        @apply tw-font-medium tw-no-underline;
    }

    &.white {
        @apply tw-bg-white tw-transition-colors tw-duration-300 tw-ease-in-out hover:tw-bg-brand-500 hover:tw-text-white hover:tw-outline-brand-500;
        padding: 0.7rem;
    }
    &.rounded {
        @apply tw-rounded-full;
    }
    &.btn-sm {
        padding: 6px 10px;
    }
    &.btn-configurator {
        @apply tw-cursor-pointer tw-px-3 tw-py-2 tw-font-bold lg:tw-px-4 lg:tw-py-3 xl:tw-px-5 xl:tw-py-4 tw-transition-colors tw-duration-300 tw-ease-in-out;

        &[disabled] {
            @apply tw-bg-gray-100 tw-text-gray-300;
        }
    }
    &.btn-configurator-pill,
    &.btn-configurator-circle,
    {
        @apply tw-cursor-pointer tw-px-3 xl:tw-px-4 tw-font-bold tw-transition-colors tw-duration-300 tw-ease-in-out;

        &[disabled] {
            @apply tw-bg-gray-100 tw-text-gray-300;
        }
    }

    &.btn-configurator-circle {
        @apply tw-px-2 md:tw-px-3 xl:tw-px-4;
    }
}

