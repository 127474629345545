html,
body {
    font-family: theme('fontFamily.roboto');
    scroll-behavior: smooth;
    font-size: 16px;
}

[x-cloak] {
    @apply tw-hidden;
}

.gradient-corner {
    &::after {
        @apply tw-absolute tw-inset-0 tw-h-full tw-w-full tw-pointer-events-none;
        content: '';
        background: linear-gradient(-45deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 50%, rgba(0,0,0,0.5) 100%);
    }
}