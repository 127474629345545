.TeaserSlider {
    .arrow {
        @apply tw-transition-transform;

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        &:hover {
            @apply tw-opacity-50 tw-transition-opacity;
        }
    }

    .bullet {
        .bullet-dot {
            max-width: 10px;
            max-height: 10px;
        }

        &:hover .bullet-dot {
            @apply tw-bg-brand-300;
        }
        &.active .bullet-dot {
            @apply tw-bg-brand-500;
        }
    }

    @media screen and (min-width: theme('screens.md')) {
        .nav-bullets .bullet-outer:nth-last-child(-n+1) {
            display: none !important;
        }
    }

    @media screen and (min-width: theme('screens.lg')) {
        .nav-bullets .bullet-outer:nth-last-child(-n+2) {
            display: none !important;
        }
        &.has-4-columns .nav-bullets .bullet-outer:nth-last-child(-n+3) {
            display: none !important;
        }
    }
}
