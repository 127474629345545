.Newsletter{
    @apply tw-text-16;

    label{
        @apply tw-text-14 tw-font-normal tw-text-gray-700;
    }

    button[type="submit"]{
        @apply tw-bg-brand-500 tw-text-white tw-px-6 tw-py-4 tw-uppercase tw-mt-10 tw-font-bold;
    }
}