:root {
    --cc-primary-color: theme('colors.brand.500');
    --cc-secondary-color: theme('colors.brand.500');
    --cc-text-color: theme('colors.gray.700');
    --cc-text-color-headline: theme('colors.black');
    --cc-modal-box-background: theme('colors.white'); // background of content
    --cc-border-radius: 1px;
    --cc-border-color: theme('colors.gray.300');
    --cc-switch-bg-inactive: theme('colors.gray.300');
    --cc-background: rgba(21,34,41, 0.85); // background for modal, body-before-element
    --cc-btn-primary-hover-bg: theme('colors.brand.700');
    --cc-btn-secondary-hover-bg: theme('colors.brand.700');
    --cc-btn-default-hover-bg: theme('colors.gray.300');
}

@import "@AccWebsiteBundle/GdprConsent/GdprConsent";