:root{
    --height-screen: 100vh;

    @supports (height: 100svh) {
        --height-screen: 100svh;
    }
}

.height-screen{
    height: var(--height-screen);
}

.height-screen-minus-35-vw{
    height: calc(var(--height-screen) - 35vw);
}