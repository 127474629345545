.ImageHotspots {
    .slider-item {
        .hotspot {
            .hotspotContent {
                @apply tw-hidden;
                transition: transform .8s ease-in-out;
            }
            svg {
                @apply tw-transition-transform tw-duration-700;
            }
            &.open {
                @media screen and (min-width: theme('screens.md')) {
                    @apply tw-animate-fadeIn;
                }

                @media screen and (min-width: theme('screens.md')) {
                    @apply tw-drop-shadow-imageHotspot;
                }

                &.portrait {
                    @apply tw-max-w-none;

                    &.text {
                        @media screen and (min-width: theme('screens.md')) {
                            @apply tw-w-96 xl:tw-w-[28rem]; 
                        }
                    }

                    &.no-text {
                        @media screen and (min-width: theme('screens.md')) {
                            height: 85% !important;
                            max-height: 568px;
                        }
                    }
                }

                &.text-only {
                    @apply md:tw-w-60 lg:tw-w-96;
                }

                &.hotspotPoint {
                    @apply tw-bg-brand-500;
                    .hotspotMobileName {
                        @apply tw-text-white;
                    }
                    @media screen and (min-width: theme('screens.md')) {
                        @apply tw-bg-white tw-z-20;
                        .hotspotContent {
                            @apply tw-flex tw-grow;
                        }
                    }
                }

                svg {
                    @apply tw-rotate-45;
                }

                .hotspotMobileContent {
                    @apply tw-flex tw-flex-col;
                }

                .hotspotMobileButton {
                    @apply tw-text-brand-500;
                }
            }

        }
    }

    .arrow {
        @apply tw-transition-transform;

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        &:hover {
            @apply tw-opacity-50 tw-transition-opacity;
        }
    }

    .bullet {
        .bullet-dot {
            max-width: 10px;
            max-height: 10px;
        }

        &:hover .bullet-dot {
            @apply tw-bg-brand-300;
        }
        &.active .bullet-dot {
            @apply tw-bg-brand-500;
        }
    }
}
