.Datasheet {
    label {
        @apply tw-text-white;

        &::after {
            content: none !important;
        }
    }

    select {
        option:disabled {
            @apply tw-hidden;
        }
    }
}
