form {
    label {
        @apply tw-text-sm;

        &:not(.required) {
            &::after {
                content: " (optional)";
            }
        }

        &.required {
            &::after {
                content: " *";
            }
        }

        a {
            @apply tw-text-brand-500;
            text-decoration: underline;
        }
    }

    .prevent-optional-flag{
        label::after{
            content: "";
        }
    }

    .choice-widget-expanded{
        @apply tw-mb-3;

        label{
            margin-bottom: 0 !important;
        }
    }

    input,
    textarea,
    select {
        @apply tw-px-5 tw-py-3 tw-bg-white tw-leading-normal;
    }

    select {
        @apply tw-appearance-none tw-bg-no-repeat;
        background-position: right 0.5rem center;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.86079 1.06005C0.766133 0.966169 0.712891 0.83837 0.712891 0.705052C0.712891 0.571733 0.766133 0.443935 0.86079 0.350052L1.06079 0.150051C1.15487 0.0523928 1.2852 -0.00191455 1.42079 5.15903e-05H8.58079C8.71638 -0.00191455 8.84671 0.0523928 8.94079 0.150051L9.14079 0.350052C9.23545 0.443935 9.28869 0.571733 9.28869 0.705052C9.28869 0.83837 9.23545 0.966169 9.14079 1.06005L5.35079 4.85005C5.26006 4.94673 5.13338 5.00157 5.00079 5.00157C4.8682 5.00157 4.74152 4.94673 4.65079 4.85005L0.86079 1.06005Z' fill='%23A68659'/%3E%3C/svg%3E%0A");
    }

    .radio,
    .checkbox {
        @apply tw-relative tw-pl-7 tw-shrink-0;

        input {
            @apply tw-hidden;
        }

        label {
            @apply tw-text-sm;
            cursor: pointer;

            &::before,
            &::after {
                @apply tw-absolute tw-left-0 tw-block tw-w-5 tw-h-5;
                content: "" !important;
            }

            &::before {
                @apply tw-rounded tw-bg-white;
            }

            &::after {
                @apply tw-top-0 tw-ease-in-out tw-duration-300 tw-rounded;
                background: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='%23A68659' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.8499 2.25012L6.24985 12.8501C6.15597 12.9448 6.02817 12.998 5.89485 12.998C5.76153 12.998 5.63374 12.9448 5.53985 12.8501L0.149852 7.46012C0.0551959 7.36623 0.00195312 7.23844 0.00195312 7.10512C0.00195312 6.9718 0.0551959 6.844 0.149852 6.75012L0.849852 6.05012C0.943735 5.95546 1.07153 5.90222 1.20485 5.90222C1.33817 5.90222 1.46597 5.95546 1.55985 6.05012L5.88985 10.3801L15.4399 0.830117C15.6377 0.638333 15.952 0.638333 16.1499 0.830117L16.8499 1.54012C16.9445 1.634 16.9978 1.7618 16.9978 1.89512C16.9978 2.02844 16.9445 2.15623 16.8499 2.25012Z' fill='%23A68659'/%3E%3C/svg%3E%0A") center no-repeat;
                background-size: 80%;
                content: "";
                opacity: 0;
                visibility: hidden;
                transition-property: visibility, opacity;
            }
        }

        input:checked ~ label {
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }

        &-custom {
            @apply tw-text-sm tw-shrink-0;
        }
    }

    .form-row {
        &.has-error {
            > label {
                color: theme('colors.red');
            }

            input, select, textarea {
                border: 2px solid theme('colors.red') !important;
            }

            .error-icon,
            .error-message {
                @apply tw-block;
            }

            .checkbox,
            .radio {
                label {
                    @apply tw-text-red;

                    &::before {
                        border: 2px solid theme('colors.red') !important;
                    }
                }
            }
        }

        .error-message {
            @apply tw-hidden tw-text-xs tw-mt-1;
            color: theme('colors.red');
        }

        .error-icon {
            @apply tw-hidden tw-absolute tw-text-red tw-top-3 tw-right-5 tw-w-6 tw-h-6;
            background: url(../images/icons/error.svg) center no-repeat;
            background-size: contain;
        }
    }

    .theme-border {
        @apply tw-border-none tw-rounded-sm;
    }

    &[name="partner_search"],
    &[name="partner_search_private"] {
        label {
            &::after {
                content: "" !important;
            }
        }
    }
}

input[type="checkbox"] {
    &.checkmark {
        &:checked {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
        }
    }
}