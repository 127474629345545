.checkout-steps {

    counter-reset: step-counter;

    .checkout-step {
        @apply tw-invisible tw-absolute tw-pointer-events-none md:tw-visible md:tw-relative md:tw-pointer-events-auto md:tw-block tw-text-center tw-rounded-full tw-border tw-border-gray-100 tw-px-4 tw-py-2 tw-font-medium;

        &.active {
            @apply tw-visible tw-relative tw-pointer-events-auto tw-block tw-w-full md:tw-w-auto tw-text-brand-500 tw-border-brand-500;
        }

        &.done {
            @apply tw-text-brand-500;
        }

        &::before {
            counter-increment: step-counter;
            content: counter(step-counter) " • ";
        }
    }
}

.checkbox {
    @apply tw-flex tw-justify-start tw-items-baseline;

    label {
        &::before {
            @apply tw-border tw-border-gray-300 tw-border-solid;
        }
    }
}

.Cart {
    .remove-item {
        &::after {
            content: 'x';
        }

        &:before,
        &:after {
            @apply tw-absolute tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-top-1/2 tw-left-1/2 tw-indent-0 tw-text-lg tw-w-[18px] tw-h-0.5 tw-duration-200 tw-transition-transform;
            content: "";
            background-color: currentColor;
        }

        &:before {
            @apply tw--translate-x-1/2 tw--translate-y-1/2 tw-rotate-45;
        }

        &:after {
            @apply tw--translate-x-1/2 tw--translate-y-1/2 tw--rotate-45;
        }

        &:hover {
            &:before {
                @apply tw--translate-x-1/2 tw--translate-y-1/2 tw--rotate-180;
            }

            &:after {
                @apply tw--translate-x-1/2 tw--translate-y-1/2 tw-rotate-180;
            }
        }
    }
}

.CheckoutAddress {
    .theme-border:not(label) {
        @apply tw-border-gray-100 tw-border-solid tw-border tw-rounded-sm focus:tw-border-brand-500;
    }
}